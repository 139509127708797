class {
  onCreate(input) {
    const yearList = [];
    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i >= 2015; i--) {
      yearList.push(i);
    }

    this.state = {
      searchText: input.termFromSearch || '',
      searchYear: input.yearFromSearch || undefined,
      yearList,
    };
  }

  goToSearchPage() {
    const searchUrl = `/search${this.state.searchYear ? `/${this.state.searchYear}` : ''}?s=${this.state.searchText}`;
    if (this.state.searchText !== '') window.location.href = searchUrl;
  }

  updateSearchText(event) {
    if (event.key !== 'Enter') {
      this.state.searchText = event.target.value;
    } else this.goToSearchPage();
  }

  updateSearchYear(event) {
    if (event.target.value === 'upcoming') this.state.searchYear = undefined;
    else this.state.searchYear = event.target.value;
  }
}

$ const { isInHeader } = input

style.scss {
  @use '../style/mixins' as mx;

  .ht-search-combo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 800px;
    margin: auto;
    padding: 0 0.25rem 0 0;
    border-radius: 50px;
    background-color: var(--white);
    overflow: hidden;

    @include mx.mq(max-medium) {
      width: 100%;
    }
    @include mx.mq(large) {
      min-width: 380px;
    }

    &.border {
      box-shadow: 0 0 1px 1px var(--grey-light);
    }
    &__input {
      padding: 0.5rem 0 0.5rem 1rem;
      border: none;
      border-radius: 50px 0 0 50px;
    }
    &__select {
      flex: 0 0 auto;
      width: 125px;
      height: 44px;
      border: none;
      text-align: center;

      &:hover {
        background-color: var(--color-primary-a20);
      }
    }
    &__button {
      flex: 0 0 auto;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 100%;
      border: none;
      background-color: var(--grey-lightest);
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary-a20);
      }
    }
  }
}

<div class=['ht-search-combo', { border: isInHeader }]>
  <input
    class="ht-search-combo__input"
    type="text"
    placeholder="title, theme, city or country"
    value=state.searchText
    on-keyup('updateSearchText')
    on-enter('goToSearchPage')
  >
  <select
    class="ht-search-combo__select"
    on-change('updateSearchYear')
    on-enter('goToSearchPage')
  >
    <option value="upcoming" selected=!state.searchYear>
      Upcoming
    </option>
    <for|year| of=state.yearList>
      <option value=year selected=parseInt(state.searchYear, 10) === year>${year}</option>
    </for>
  </select>
  <button
    class="ht-search-combo__button"
    aria-label="search"
    on-click('goToSearchPage')
  ><ht-icon name="idea-ht-search"/></button>
</div>
