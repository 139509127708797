class {
  onCreate() {
    this.state = {
      links: [
        {
          label: 'Corporate Services',
          url: 'https://corporate.hackathon.com/'
        },
      ],
      menuIsOpen: false
    }
  }

  toggleMenuMobile() {
    this.state.menuIsOpen = !this.state.menuIsOpen;
  }
}

style.scss {
  @use '../style/mixins' as mx;

  .ht-header {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 70px;
    background-color: var(--white);
    z-index: 10;

    @include mx.mq(max-large) {
      flex-wrap: wrap;
      padding: 0 1rem;
    }
    @include mx.mq(large) {
      gap: 2rem;
      padding: 0 2rem;
    }
    &:not(.home) {
      border-bottom: 1px solid var(--grey-light);
      background-color: var(--white);
    }
    &.home {
      a {
        color: var(--white);
      }
    }
    &__home-link {
      width: 134px;
      padding-top: 0.5rem;
      line-height: 0;
    }
    &__navbar {
      display: flex;
      align-items: center;
      gap: 2rem;
      flex: 1 1 auto;

      @include mx.mq(max-large) {
        flex-direction: column;
        width: 100%;
        height: 0;
        background-color: var(--white);
        overflow: hidden;

        &.open {
          height: calc(100vh - var(--menu-height));
          padding: 1rem 0;
        }
      }
      @include mx.mq(large) {
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        flex: 1 1 auto;
      }
    }
    &__nav {
      display: flex;
      align-items: center;
      gap: 2rem;

      @include mx.mq(large) {
        margin-left: auto;
      }

      &__link {
        color: var(--grey);
        font-size: 0.75rem;
        font-weight: 700;
        text-transform: uppercase;

        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
  .idt-menu-mobile-toggle {
    @include mx.mq(max-small) {
      flex: 0 0 auto;
    }
    @include mx.mq(max-large) {
      display: block;
      position: relative;
      width: calc(var(--menu-height) * 0.75);
      height: var(--menu-height);
      margin-left: auto;
      border: none;
      background: none;
      color: var(--color-primary-dark);

      &.is-active {
        span {
          &:nth-child(1) {
            transform: translateX(-50%) translateY(11px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateX(-50%) translateY(-11px) rotate(-45deg);
          }
        }
      }
      span {
        display: block;
        position: absolute;
        left: calc(50%);
        width: 35px;
        height: 3px;
        background-color: currentColor;
        transition: all 86ms ease-out;
        transform: translateX(-50%);

        &:nth-child(1) {
          top: calc(50% - 12px);
        }
        &:nth-child(2) {
          top: calc(50% - 1px);
        }
        &:nth-child(3) {
          top: calc(50% + 10px);
        }
      }
    }
    @include mx.mq(large) {
      display: none;
    }
  }
}

<header class="ht-header">
  <a
    class="ht-header__home-link"
    href="/"
    aria-label="Site homepage"
  >
    <ht-logo/>
  </a>

  <button
    class=['idt-menu-mobile-toggle', { 'is-active': state.menuIsOpen }]
    aria-label="menu"
    aria-expanded="false"
    on-click('toggleMenuMobile')
  >
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
  </button>
  <div class=['ht-header__navbar', { 'open': state.menuIsOpen }]>
    <div class="ht-header__search">
      <ht-search-combo is-in-header=true/>
    </div>
    <nav class="ht-header__nav">
      <for|link, index| of=state.links>
        <a
          class="ht-header__nav__link"
          key=index
          href=link.url
          target="_blank"
          data-utm="Header"
        >
          ${link.label}
        </a>
      </for>
      <!-- <a
        class="button"
        href="/create-event/1"
      >
        Publish an event
      </a> -->
    </nav>
  </div>
</header>
